import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledColocationBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [80]
  })
)

export const styledColocation = css`
  ${styledColocationBps};
`

/* HEADER */
export const H4HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    marginTop: [8],
    marginBottom: [16],
    letterSpacing: [0.25],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const H5ContentBps = css(
  mq({
    maxWidth: [328, 422, 422, 422],
    textAlign: ['left'],
    marginBottom: [8]
  })
)

export const PContentBps = css(
  mq({
    maxWidth: [328, 422, 422, 422]
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    textAlign: ['left']
  })
)

export const spanBps = css(
  mq({
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const lineBorderBps = css(
  mq({
    maxWidth: [328, 422, 862, 862]
  })
)

export const colocationItemsBps = css(
  mq({
    marginLeft: [0, 0, 112, 211],
    marginRight: [0, 0, 106, 211]
  })
)

export const IdProtectBoxBps = css(
  mq({
    width: [328, 422, 680, 680]
  })
)

export const header = css`
  display: flex;
  text-align: center;
  span {
    ${spanBps};
    text-transform: uppercase;
    font-size: 11.8px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
    display: grid;
  }
  p {
    ${PHeaderBps};
    margin-bottom: 30px;
  }
`

export const lineBorder = css`
  ${lineBorderBps};
  border-bottom: solid 1px rgba(217, 222, 227, 0.5);
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
`

export const colocationItems = css`
  ${colocationItemsBps};
  h5 {
    ${H5ContentBps};
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    ${PContentBps};
  }
`
export const colocationForms = css`
  text-align: center;
  span {
    font-size: 11.8px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: 2px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    text-transform: uppercase;
  }
  & > div {
    margin-top: 16px;
  }
`

export const IdProtectBox = css`
  ${IdProtectBoxBps};
  margin: 0 auto !important;
`

export const IdProtectList = css`
  display: flex;
  align-items: end;
  margin-bottom: 16px;
  img {
    margin-right: 16px;
  }
  span {
    font-size: 15.7px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.53;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
  }
`